import {
  SELL_LOADING,
  SELL_END_LOADING,
  GET_SELL,
  GET_SELLS,
  SELL_SUCCESS,
  SELL_FAILED,
  GET_SELL_REPORT,
  CLEAR_SELL_STATE,
  SELL_ORDER_PRODUCTS,
  SELL_UPDATE_PRODUCTS,
} from "../actions/types";
import { getResponsePayload } from "../utils/reducerUtils";
const initialState = {
  loading: false,
  sell_success: false,
  sell: {},
  sells: [],
  report: {},
  newSellDetails: {},
  pagination: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SELL_LOADING:
      return {
        ...state,
        loading: true,
      };

    case SELL_END_LOADING:
      return {
        ...state,
        loading: false,
      };

    case SELL_UPDATE_PRODUCTS: {
      state.sell.productos = action.payload;
      return {
        ...state,
        loading: false,
      };
    }

    case GET_SELL:
      var sell = action.payload;
      if (sell.productos) {
        for (let index = 0; index < sell.productos.length; index++) {
          const producto = sell.productos[index];
          if (producto.indice == null) {
            producto.indice = index + 1;
          }
          sell.productos[index] = producto;
        }
        sell.productos = sell.productos.sort((a, b) => {
          return parseInt(a.indice) > parseInt(b.indice) ? 1 : -1;
        });
      }
      return {
        ...state,
        loading: false,
        sell,
      };

    case GET_SELLS:
      const data = getResponsePayload(action);
      return {
        ...state,
        loading: false,
        sells: data.elements,
        pagination: data.pagination,
      };

    case SELL_SUCCESS:
      return {
        ...state,
        loading: false,
        sell_success: true,
        newSellDetails: action.payload || {},
      };

    case SELL_FAILED:
      return {
        ...state,
        loading: false,
        sell_success: false,
        newSellDetails: {},
      };

    case GET_SELL_REPORT:
      return {
        ...state,
        loading: false,
        report: action.payload,
      };

    case CLEAR_SELL_STATE:
      return {
        ...state,
        loading: false,
        sell_success: false,
        newSellDetails: {},
        report: {},
        sell: {},
        sells: [],
      };

    case SELL_ORDER_PRODUCTS: {
      const products = state.sell.productos.sort((a, b) => {
        return parseInt(a.indice) > parseInt(b.indice) ? 1 : -1;
      });
      state.sell.productos = products;
      return {
        ...state,
      };
    }

    default:
      return state;
  }
}
